<template>
    <div class="rounded bg-gray-700 py-6 transition-all">
        <img
            src="/img/icons/23m/dedicated-server.svg"
            alt="Dedicated Server"
            class="mx-auto mb-12 h-24 w-36"
        />

        <div class="m-6 flex flex-wrap items-center">
            <BadgeButton
                v-model="selectedFilters"
                :badge="allBadge"
                class="mx-1 mb-2 md:mx-0 md:mr-1"
                @update:modelValue="activateAllBadge"
                data-spec-class="special_deals_list__filter-badge"
                data-spec-id="special_deals_list__filter-badge--all"
            >
                {{ $t('general.all') }}
            </BadgeButton>

            <span class="mx-1 mb-2 h-6 border-r border-gray-400"> </span>

            <template
                v-for="(badges, groupId, y) in groupedBadges"
                :key="groupId"
            >
                <BadgeButton
                    v-for="(badge, i) in badges"
                    v-model="selectedFilters"
                    :key="i"
                    :badge="badge"
                    class="mx-1 mb-2"
                    @update:modelValue="onUpdateFilters"
                    data-spec-class="special_deals_list__filter-badge"
                    :data-spec-id="`special_deals_list__filter-badge--${badge.id}`"
                >
                    {{ badge.name }}
                </BadgeButton>
                <span
                    v-if="y !== Object.values(groupedBadges).length - 1"
                    class="mx-1 mb-2 h-6 border-r border-gray-400"
                >
                </span>
            </template>
        </div>

        <div class="m-6 hidden grid-cols-6 gap-6 text-lg lg:grid">
            <div class="text-left text-blue-700">{{ $t('configuratorDedicated.base') }}</div>
            <div class="text-blue-700">RAM</div>
            <div class="text-blue-700">Storage</div>
            <div class="text-blue-700">{{ $t('configuratorGeneral.network') }}</div>
            <div class="text-blue-700">{{ $t('configuratorGeneral.price') }}</div>
        </div>

        <transition-group
            class="relative m-6"
            name="fade"
            tag="div"
        >
            <SpecialDealsListItem
                v-for="server in currentChunk"
                :key="server.id"
                class="mb-3 w-full"
                :server="server"
                :assortmentContent="assortmentContent"
                :assortmentNetworkContent="assortmentNetworkContent"
                :assortmentIpContent="assortmentIpContent"
            />

            <div
                class="mb-12 flex w-full flex-col items-center rounded border border-gray-500 bg-gray-600 p-3"
                v-if="meta.total === 0"
                data-spec-class="special_deals_list__no-results"
            >
                <span class="text-gray-300"> {{ $t('general.noResultsFound') }} </span>
                <span class="text-sm text-gray-400">
                    {{ $t('general.noResultsFoundDescription') }}
                </span>

                <BaseLink
                    class="mt-1"
                    @click="activateAllBadge"
                >
                    {{ $t('actions.clearFilters') }}
                </BaseLink>
            </div>
        </transition-group>

        <BasePagination
            v-if="meta.total > perPage"
            :meta="meta"
            :theme="Theme.Dark"
            @update:page="(page) => onPageUpdate(page)"
            class="mx-6"
        />

        <div class="mb-3 mt-6 flex flex-col items-center lg:mx-12 lg:mt-3">
            <div class="whitespace-nowrap text-xl text-blue-700 lg:ml-12 lg:mr-6">
                {{ $t('configuratorSpecialDeals.crossLinkTeaser') }}
            </div>

            <div class="mx-6 text-gray-300 lg:ml-6 lg:mr-12 lg:w-1/2">
                <span
                    v-html="$t('configuratorSpecialDeals.crossLink', { href: '/dedicated-server' })"
                >
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAssortmentSpecialDeal } from '../composables/assortmentSpecialDeal';
import BadgeButton from './Configurator/BadgeButton.vue';
import { usePagination } from '../../../composables/pagination';
import { useI18n } from 'vue-i18n';

import SpecialDealsListItem from './SpecialDealsListItem.vue';
import { Theme } from '../../../types';

const { t } = useI18n();

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const allBadge = {
    id: 0,
    group_id: 0,
    name: t('general.all'),
    color: '#00afcb',
};

const { assortment, groupedBadges } = useAssortmentSpecialDeal(
    props.assortmentContent,
    props.assortmentNetworkContent,
    props.assortmentIpContent
);

const selectedFilters = ref([0]);

const servers = Object.values(assortment.servers);
const filteredServers = computed(() => {
    if (selectedFilters.value.includes(allBadge.id)) {
        return servers;
    }

    return servers.filter((server) => {
        return selectedFilters.value.every((serverBadge) => {
            return server.badges.map((badge) => badge.id).includes(serverBadge);
        });
    });
});

const perPage = 10;
const { meta, currentChunk, onPageUpdate, setPage1 } = usePagination(filteredServers, perPage);

const deactivateAllBadge = () => {
    selectedFilters.value = selectedFilters.value.filter((v) => v !== allBadge.id);
};

const activateAllBadge = () => {
    selectedFilters.value = [allBadge.id];
};

const onUpdateFilters = () => {
    deactivateAllBadge();
    if (selectedFilters.value.length === 0) {
        activateAllBadge();
    }
    setPage1();
};
</script>

<style scoped>
.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleX(1) translate(30px, 0);
}

.fade-leave-active {
    position: absolute;
}
</style>
